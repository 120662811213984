import React from "react"
import SEO from "../components/seo"
import { kebabCase } from "lodash"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"
import Container from "../components/Container"
import Row from "../components/Row"
import Layout from "../components/Layout"

class AuthorRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges

    let rowContents = []
    const postLinks = posts.map(({ node: post }, i) => {
      let acc = []
      rowContents.push(
        <div className="col s12 m4" key={post.id}>
          <article className="card">
            {post.frontmatter.featuredimage ? (
              <div className="card-image">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.frontmatter.featuredimage,
                    alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                  }}
                />
              </div>
            ) : null}
            <div className="card-content">
              <p>
                <Link
                  className="landing-page-header blog-title"
                  to={post.fields.slug}
                >
                  {post.frontmatter.title}
                </Link>
                <br />
                <div>
                  <span
                    className="blog-post-date"
                    style={{ display: "inline-block" }}
                  >
                    {post.frontmatter.date}
                    &nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;{post.timeToRead} min read
                  </span>
                </div>
              </p>
              <Link
                className="blog-post-date"
                to={`/authors/${kebabCase(post.frontmatter.author)}`}
              >
                {post.frontmatter.author}
              </Link>
              <p className="blog-description">{post.frontmatter.description}</p>
            </div>
            <Link className="button blog-roll-link" to={post.fields.slug}>
              Keep Reading &#62;
            </Link>
          </article>
        </div>
      )
      if ((i + 1) % 3 === 0) {
        acc.push(<Row>{rowContents}</Row>)
        rowContents = []
      }
      return acc
    }, [])
    postLinks.push(<Row>{rowContents}</Row>)

    const author = this.props.pageContext.author
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const authorHeader = `${totalCount} post${
      totalCount === 1 ? "" : "s"
    } written by “${author}”`

    return (
      <>
        <Helmet title={`${author} | ${title}`}>
          {/* Font Awesome */}
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
            integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay"
            crossorigin="anonymous"
          />
          {/* Materialize Icons */}
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
            rel="stylesheet"
          />
          {/* Google Fonts */}
          <link
            href="https://fonts.googleapis.com/css?family=Oswald&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <SEO title={`Author | ${author}`} />
        <Layout>
          <Container>
            <Row>
              <div className="col s12">
                <h1 className="blog-header">{authorHeader}</h1>
              </div>
            </Row>
            <Row>
              <p className="browse-tags">
                <Link to="/authors/">Browse all authors &#62;</Link>
              </p>
            </Row>
            {postLinks}
          </Container>
        </Layout>
      </>
    )
  }
}

export default AuthorRoute

export const authorPageQuery = graphql`
  query AuthorPage($author: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { author: { in: [$author] } } }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          timeToRead
          frontmatter {
            author
            description
            title
            date(formatString: "MMMM DD, YYYY")
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 450, maxHeight: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
